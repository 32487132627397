.timeline-outer-container{
    border: 0.5px solid lightgray;
    margin-bottom: 10px;
    height: 100%;
    border-radius: 5px;
    margin-right: 10px;
    padding-bottom: 0px;
}

.timeline-outer-container-two{
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        background-color: white
}

.timeline-border-gray{
    border: 0.1px solid lightgray
}