.unselectedButton{
   height:50px;
   width:250px;
   border:1px solid lightgray;
   border-radius: 2px;
   text-align:center;
   margin-bottom: 20px;

}

.selectedButton{
   height:50px;
   width:250px;
   text-align:center;
   border-radius: 2px;
   background-color:#EBF4FA;
   margin-bottom: 20px;
}

.top-left-container{
      border: 0.5px solid lightgray;
      margin-bottom: 10px;
      height: 100%;
      border-radius: 5px;
      margin-right: 10px
}

.top-right-container{
   border: 0.5px solid lightgray;
   margin-bottom: 10px;
   height: 100%;
   border-radius: 5px;
   margin-right: 10px
}

.lower-container{
   border: 0.5px solid lightgray;
}
.button-container{
   display:flex;
   margin-left:'auto'
}