/*----------------login css------------------*/

$theme-color: #41a6f7;
$white: #ffffff;

@function pxToRem($value: 16) {
  @return (($value / 16) + rem);
}

$headerHeight: pxToRem(80);

.logo-img {
  width: 22vh;
  display: flex;
  padding: 2%;
  margin: auto;
}

#leftImgDiv {
  background-size: cover;
  background-position: center;
}

#forgtpas {
  float: right;
}

.navTopLeft {
  padding-top: 14px;
}

.app-info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  margin-top: 80vh;

  h1 {
    margin: auto;
  }
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected {
  background-color: #0055AF
}

.btn-contact {
  padding-right: 42px;
  padding-left: 40px;
  margin-top: 40px;
  color: #44677b;
  font-weight: 700;
  background-color: #f3f0f0 !important;
  height: 35px;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 1rem;
}

.copyright-text {
  font-size: 0.8rem;
  margin: auto;
}

// .sign-in-text {
//   margin: 0;
//   font-weight: 500;
// }

// .signin-form-input {
//   display: block;
//   width: 100%;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   color: #495057;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #ced4da;
//   border-radius: 0.25rem;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

.signin-form-container {
  background-color: #fbfbfb !important;
  padding-top: 2%;

  p,
  label {
    font-size: 0.8rem;
  }
}

// .signin-form {
//   input {
//     background: #ffffff;
//     box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%);
//     height: 41px;
//     box-sizing: border-box;
//     border-left: 1px solid #c4c4c4;
//     margin-left: 2px;
//     padding-left: 5px;
//   }

//   .input-group {
//     margin-bottom: 1rem;
//   }

//   .btn-signin {
//     padding-right: 42px;
//     padding-left: 40px;
//     margin-top: 10px;
//     background-color: $theme-color;
//     box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
//     color: #fff;
//     font-weight: 700;
//     height: 35px;
//     border-radius: 3px;
//     border: 1px solid transparent;
//     font-size: 1rem;
//     cursor: pointer;
//     width: 100%;
//   }

//   .btn-signin:disabled {
//     opacity: 0.3;
//   }
// }

.login-options {
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .login-options {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .loginBackground {
    display: none;
  }
}



.firebase-sign-in {
  margin: 1% auto;
  display: flex;
  justify-content: center;


  .firebaseui-card-content {
    padding: 0;
  }

  .firebaseui-idp-text {
    color: #757575;
  }
}

.forgot-pass-disabled {
  pointer-events: none;
  opacity: 0.6;
}

#btnReset {
  margin-top: 10px;
  color: #44677b;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

#btnReset:disabled {
  opacity: 0.3;
}

.h-md-100 {
  min-height: 100vh;
  height: 100vh;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}

.error-border {
  border: 1px solid #ffc0cb !important;
  box-shadow: 0 1px 15px 1px rgb(255 0 0 / 7%);
}

.login-form-adornment {
  color: $theme-color;
}

.partial-partition {
  width: 40%;
  border: 1px solid #dcdcdc;
  display: inline-flex;
  vertical-align: sub;
}

.partition-or {
  display: inline-flex;
  margin-inline: 2%;
  color: #68727e;
}

.partitioner {
  width: 70%;
  margin: 3% auto 0 auto;
}

.contact-btn {
  color: $theme-color;
  border: 1px solid $theme-color;
}

.contact-btn-container {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}


.marketing {
  &-container {
    background: $white url("../../assets/Marketing_BG.png") center/cover;
    padding: 3.4rem;
  }

  &__header {
    color: #1D1D1D;
    font-weight: 400;
    line-height: 2.25rem;
    letter-spacing: 0.04em;
  }

  &__image {
    width: 100%;

    &-wrapper {
      margin: 0 auto;
      max-width: pxToRem(420);
      padding: 5.375rem 0;
    }
  }

  &__description {
    &-wrapper {
      margin: 0 auto 1.5rem;
      max-width: pxToRem(540);
      padding: 0 3rem;
      text-align: center;
    }
  }

  &__indicators {
    text-align: center;

    .icons {
      color: #C8C7C7;
      cursor: pointer;
      font-size: 0.625rem;
      margin: 0 0.3rem;
      
      &.active {
        color: #0055AF;
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

.signin-form {
  &__header {
    background-color: $white;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    height: $headerHeight;
    padding: 1.25rem 1.875rem;
    position: relative;

    .header__logo {
      height: 100%;
    }

    .brand__title {
      color: #1D1D1D;
      font-weight: 400;
      letter-spacing: 0.02em;
      line-height: normal;
    }
  }

  &-container {
    background-color: $white;
    padding: 3.4rem;
  
    .sign-in-text {
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 4rem;
    }
  
    .signin-form {
      .btn {
        display: flex;
        margin-bottom: 1.825rem;
        margin-left: auto;
        padding: 0;
      }
    
      .btn-signin {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        margin-top: 30px;
      }
    
      .MuiInputLabel-root {
        color: #1B2E42;
        font-weight: 500;
        margin-bottom: 0.25rem;
        margin-top: 30px;
      }
    
      .MuiInputBase-input {
        color: #475569;
        font-weight: 300;
        font-size: 14px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 62rem white inset !important;
        }
      }
    }
    
    .login-options {
      margin: 50px auto;
      padding: pxToRem(50);
      max-width: pxToRem(520);
      border: 1px solid #DBDBDB;
      border-radius: 0.5rem;
  
      .login__wrapper { 
        max-width: 20rem;
        margin: 0 auto;
      }
    
      .MuiDivider-root {
        justify-content: center;
        color: #6E7E95;
        margin-top: 1rem;
    
        &::after, &::before {
          width: 0.5rem;
          border-color: #6E7E95;
        }
      }
    }
    
    .firebase-sign-in,
    .firebase-without-emailLogin {
      margin-top: 1rem;
  
      .firebaseui-card-content{
        padding: 0;
      }
  
      .firebaseui-idp-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0 1rem;
        justify-content: center;
        margin: 0;
  
        .firebaseui-list-item {
          white-space: nowrap;
          margin-bottom: 1rem;
  
          .firebaseui-idp-text {
            color: #475569;
          }
        }
      }
    }
  
    .contact {
      color: #87919A;
    }
  }
}

.h-md-100 {
  min-height: calc(100vh - $headerHeight);
  height: 100%;
}

.error-text {
  color: red;
  font-size: 0.75rem;
  margin-top: pxToRem(3);
}

.error-border {
  border: 1px solid #ffc0cb !important;
  box-shadow: 0 1px 1rem 1px rgb(255 0 0 / 7%);
}

.login-mobile {
  display: none !important;
  font-family: 'Poppins';

  .login-button {
    text-align: center;
    color: white;
    padding: 10px 0px;
    margin-top: 18%;

    width: 100%;
    height: 40px;

    background: #0055AF;
    border-radius: 8px;
    text-transform: initial;
  }

  .logo_grid {
    opacity:0.7;
    padding-left: 8%;
    padding-top: 9%;
  }

  .title_grid {
    justify-content: right;
    text-align: right;
    padding-right: 8%;
    padding-top: 9%;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.04em;
    color: #F2FBFF;
  }

  .forgot-password-grid {
    text-align: right;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px;
    margin: 0;
    margin-top: 15px;
    letter-spacing: 0.03em;

    .MuiTypography-colorPrimary {
      color: #0055AF;
    }
  }

.google-login-box {
  width:100%
}

.need-help-lable{
  margin-top: 40px;
    font-weight: 400;
    text-align: center;
    color: #6E7E95;
    width: 100%;
    font-size: 14px !important;
    a {
      color: #0055AF
    }
}

  .continue-lable {
    margin-top: 40px;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    text-align: center;
    color: #6E7E95;
  }

  .login-form-grid {
    padding-left: 27px;
    margin-top: 17%;

    p {
      margin-bottom: 10px;
      font-size: 13px;
    }

    .MuiOutlinedInput-input {
      padding: 12px 10px 11px 17px;
      border-radius: 8px;
      border: 0
    }

    .Mui-focused {
      border: 1px solid #0055AF !important;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
      border-radius: 8px;

      .MuiOutlinedInput-notchedOutline{
        border:0
      }
    }

    .MuiInputBase-root {
      border-radius: 8px;
    }
  }

  .login-title-grid {
    padding-left: 25px;
    padding-top: 17%;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: #1D1D1D;
  }
}

@media screen and (max-width: 950px) {
  .contact-btn-container {
    display: none;
  }

  .login-desktop {
    display: none !important;
  }

  .login-mobile {
    display: flex !important;
  }
}