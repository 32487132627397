.calendar-date {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.dp-input-container {
  display: flex !important;
  align-items: center;
  justify-content: space-around;
}
.calendar-date_disable {
  background: #e4e7e7;
  border: 1px solid #ced4d4;
  pointer-events: none;
}
.calendar-date_default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff;
}
.calendar-date_hovered {
  border: 1px solid #80e8e0;
  color: #007a87;
  background: #b2f1ec;
}
.calendar-date_default-hover {
  background: #eff5f5;
  border: 1px solid #e4e7e7;
  color: inherit;
}

.DateRangePickerInput__disabled {
  background: #e9f3f8;
}

.DateInput_input__disabled {
  background: #e9f3f8;
  font-style: italic;
}

.unselectable {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(228, 231, 231);
  color: rgb(202, 204, 205);
}

.selected-date {
  background: rgb(0, 166, 153);
  border: 1px solid rgb(0, 166, 153);
  color: rgb(255, 255, 255);
}
.calendar-day {
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
}

.calendar-cell-size {
  width: 39px;
  height: 38px;
}

.calendar-width {
  width: 610px;
}

.dr-calendar-month-arrow {
  padding: 8px 12px;
  cursor: pointer;
}

.dr-calendar-container {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  margin: 20px 20px;
}

.left-arrow,
.ly-lly-date-range .left-arrow {
  left: 35px;
}

.arrow,
.ly-lly-date-range .arrow {
  position: absolute;
  /* border: 1px solid gainsboro; */
  padding: 2px 6px;
  border-radius: 8px;
}

.arrow:hover,
.ly-lly-date-range .arrow:hover {
  background: #eff5f5;
}

.right-arrow,
.ly-lly-date-range .right-arrow {
  right: 35px;
}
.ly-lly-date-range .arrow::after,
.ly-lly-date-range .arrow::before {
  visibility: hidden;
}
.ly-lly-date-range .left-arrow,
.ly-lly-date-range .right-arrow {
  top: 16px;
  padding: 2px 6px;
  width: 41px;
  height: 40px;
}
