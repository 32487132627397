.compared-outer-container{
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        background-color: white
}
#border-gray{
    border: 0.1px solid lightgray;
}

.button-group-container{
        padding: 10px;
        border-radius: 5px
}