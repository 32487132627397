.confirm-modal {
  .confirm-batch-header {
    font-size: 18px;
    display: flex;
    justify-content: center;
    margin: 0.4rem 0 1rem;
    border-bottom: 1px solid #80808073;
    padding-bottom: 10px;
  }
  .confirm-container {
    margin-top: 10px;
    h3 {
      font-weight: 100;
      text-align: center;
    }
  }
  .decision-buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    .decision-button {
      flex: 0 0 33.33333%;
      color: white;
      background: #44677b;
      border-radius: 0.4rem;
      padding: 10px 20px;
      font-size: 15px;
      text-transform: uppercase;
      border: 1px solid #44677b;
      transition: all 0.3s ease;
      align-items: center;
      box-shadow: none;
      cursor: pointer;
      &:hover {
        background: white;
        color: #44677b;
      }
    }
    .left-btn {
      margin-right: 36px;
      background: white;
      color: #44677b;
      &:hover {
        color: white;
        background: #44677b;
      }
    }
  }
}
